import React, { useState, useRef, useEffect } from "react";
import useApi from "lib/useApi";
import patchAPI from "lib/patchApi";
import postAPI from "lib/postApi";
import { useLocalStorage } from "contexts/LocalStorageContext";
import { FontAwesomeIcon } from "helpers/helpers";
import {
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Listbox,
} from "@headlessui/react";
import Notification from "components/common/NotificationAlert";

const assignableToOptions = ["member", "crowd", "organization", "campaign"];
const categoryOptions = [
  "text",
  "integer",
  "date",
  "boolean",
  "single_select",
  "birthday_day",
  "birthday_month",
  "unique_text",
  "unique_integer",
  "unique_list",
  "multi_select",
];

function Dropdown({ options, value, onChange, label }) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className="relative mt-2">
          <ListboxButton className="relative w-full cursor-pointer rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#6F23FF] sm:text-sm sm:leading-6">
            <span className="block truncate">{value || `Select ${label}`}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <FontAwesomeIcon
                icon={open ? "chevron-up" : "chevron-down"}
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <ListboxOption
                key={option}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                    active ? "bg-[#6F23FF] text-white" : "text-gray-900"
                  }`
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-semibold" : "font-normal"
                      }`}
                    >
                      {option}
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                          active ? "text-white" : "text-[#]"
                        }`}
                      >
                        <FontAwesomeIcon
                          icon="check"
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
}

function CreatableAutocomplete({ options, value, onChange }) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue) {
      e.preventDefault();
      if (!value.includes(inputValue)) {
        onChange([...value, inputValue]);
      }
      setInputValue("");
    }
  };

  const handleRemoveOption = (optionToRemove: string) => {
    onChange(value.filter((option) => option !== optionToRemove));
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        className="relative w-full cursor-pointer rounded-md mb-2 bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#6F23FF] sm:text-sm sm:leading-6"
        placeholder="Type and press Enter to add option"
      />
      <div className="mt-2 flex flex-wrap gap-2">
        {value.map((option, index) => (
          <div
            key={index}
            className="bg-gray-200 rounded-full px-3 py-1 text-sm flex items-center"
          >
            {option}
            <button
              onClick={() => handleRemoveOption(option)}
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon="times" className="h-3 w-3" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function PropertyDrawer({
  openPropertyDrawer,
  setOpenPropertyDrawer,
}: {
  openPropertyDrawer: boolean;
  setOpenPropertyDrawer: (value: boolean) => void;
}) {
  const { orgId, staff } = useLocalStorage();
  const { data, mutate } = useApi(
    `/api/orgs/${orgId}/property_definitions`,
    staff
  );
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newPropertyValues, setNewPropertyValues] = useState({
    name: "",
    field: "",
    description: "",
    assignable_to: "",
    category: "",
    options: [] as string[],
  });

  const [notification, setNotification] = useState({
    show: false,
    messages: [] as string[],
    type: "error" as "success" | "error",
  });

  const [editedProperties, setEditedProperties] = useState<{
    [key: string]: any;
  }>({});
  const [expandedHeight, setExpandedHeight] = useState<{
    [key: string]: number;
  }>({});
  const drawerRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node) &&
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setOpenPropertyDrawer(false);
      }
    }

    if (openPropertyDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPropertyDrawer, setOpenPropertyDrawer]);

  const toggleExpand = (id: string) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
      const property = data?.find((item) => item.id === id);
      if (property) {
        setEditedProperties((prev) => ({
          ...prev,
          [id]: {
            name: property.name,
            field: property.field,
            description: property.description,
            assignable_to: property.assignable_to,
            category: property.category,
            options: property.options || [],
          },
        }));
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    const { name, value } = e.target;
    if (id) {
      setEditedProperties((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [name]: value,
        },
      }));
    } else {
      setNewPropertyValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCreate = async () => {
    try {
      await mutate(async (currentData) => {
        const newProperty = await postAPI(
          `/api/orgs/${orgId}/property_definitions`,
          {
            property_definition: newPropertyValues,
          }
        );
        setNotification({
          show: true,
          messages: ["Property created successfully"],
          type: "success",
        });
        return [...(currentData || []), newProperty];
      }, false);
      setIsCreating(false);
      setNewPropertyValues({
        name: "",
        field: "",
        description: "",
        assignable_to: "",
        category: "",
        options: [],
      });
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  const handlePatch = async (id: string) => {
    try {
      await mutate(async (currentData) => {
        await patchAPI(`/api/orgs/${orgId}/property_definitions/${id}`, {
          property_definition: editedProperties[id],
        });
        setNotification({
          show: true,
          messages: ["Property updated successfully"],
          type: "success",
        });
        return currentData?.map((item) =>
          item.id === id ? { ...item, ...editedProperties[id] } : item
        );
      }, false);
      setExpandedId(null);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    let errorMessages: string[] = [];

    if (error instanceof Error) {
      try {
        const parsedError = JSON.parse(error.message);
        for (const key in parsedError) {
          if (Array.isArray(parsedError[key])) {
            errorMessages.push(...parsedError[key]);
          }
        }
      } catch {
        // If parsing fails, use the error message as is
        errorMessages.push(error.message);
      }
    }

    if (errorMessages.length === 0) {
      errorMessages.push("An unknown error occurred");
    }

    setNotification({
      show: true,
      messages: errorMessages,
      type: "error",
    });
  };

  useEffect(() => {
    if (expandedId) {
      const element = document.getElementById(`content-${expandedId}`);
      if (element) {
        setExpandedHeight((prev) => ({
          ...prev,
          [expandedId]: element.scrollHeight,
        }));
      }
    }
  }, [expandedId, editedProperties]);

  const CustomInput = (name, placeholder, value, onChange) => {
    return (
      <input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="relative w-full cursor-pointer rounded-md mb-2 bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#6F23FF] sm:text-sm sm:leading-6"
      />
    );
  };

  return (
    <>
      <div ref={notificationRef}>
        <Notification
          show={notification.show}
          setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
          messages={notification.messages}
          type={notification.type}
        />
      </div>
      <div
        ref={drawerRef}
        style={{ zIndex: 10000 }}
        className={`fixed inset-y-0 border-l-4 right-0 w-96 rounded-l-3xl bg-white drop-shadow-2xl transform ${
          openPropertyDrawer ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out overflow-y-auto`}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-md font-bold">Property Definitions</h2>
            <button
              className="bg-[#46B358] text-white px-5 py-2 rounded-md hover:bg-green-600 transition-colors"
              onClick={() => setIsCreating(!isCreating)}
            >
              {isCreating ? "Cancel" : "+ Add New"}
            </button>
          </div>

          {isCreating && (
            <div className="mb-4 border rounded-md p-4">
              <h3 className="text-sm font-bold mb-4">
                Create Property Definition
              </h3>
              <p className="font-semibold text-xs mb-1">Name</p>
              {CustomInput("name", "Name", newPropertyValues.name, (e) =>
                handleInputChange(e)
              )}
              <p className="font-semibold text-xs mb-1">Field</p>
              {CustomInput("field", "Field", newPropertyValues.field, (e) =>
                handleInputChange(e)
              )}
              <p className="font-semibold text-xs mb-1">Description</p>
              {CustomInput(
                "description",
                "Description",
                newPropertyValues.description,
                (e) => handleInputChange(e)
              )}
              <div className="mb-2">
                <p className="font-semibold text-xs mb-1">Assignable to</p>
                <Dropdown
                  options={assignableToOptions}
                  value={newPropertyValues.assignable_to}
                  onChange={(value) =>
                    setNewPropertyValues((prev) => ({
                      ...prev,
                      assignable_to: value,
                    }))
                  }
                  label="Assignable to"
                />
              </div>
              <div className="mb-2">
                <p className="font-semibold text-xs mb-1">Category</p>
                <Dropdown
                  options={categoryOptions}
                  value={newPropertyValues.category}
                  onChange={(value) =>
                    setNewPropertyValues((prev) => ({
                      ...prev,
                      category: value,
                    }))
                  }
                  label="Category"
                />
              </div>
              {(newPropertyValues.category === "single_select" ||
                newPropertyValues.category === "multi_select") && (
                <div>
                  <p className="font-semibold mb-1">Options:</p>
                  <CreatableAutocomplete
                    options={newPropertyValues.options}
                    value={newPropertyValues.options}
                    onChange={(newOptions) =>
                      setNewPropertyValues((prev) => ({
                        ...prev,
                        options: newOptions,
                      }))
                    }
                  />
                </div>
              )}
              <button
                className="mt-4 bg-[#6F23FF] text-white px-4 py-2 rounded-md hover:bg-[#6F23FF] transition-colors"
                onClick={handleCreate}
              >
                Create
              </button>
            </div>
          )}

          <div className="space-y-4">
            {data?.map((property) => (
              <div
                key={property.id}
                className="border-2 border-[#6F23FF] rounded-xl"
              >
                <div
                  className={`bg-[#6F23FF] border-[#6F23FF] text-white overflow-hidden ${
                    expandedId === property.id ? "rounded-t-lg" : "rounded-lg"
                  }  p-3 flex justify-between items-center cursor-pointer`}
                  onClick={() => toggleExpand(property.id)}
                >
                  <h3 className="font-bold text-md">{property.name}</h3>
                  <FontAwesomeIcon
                    icon={
                      expandedId === property.id ? "chevron-up" : "chevron-down"
                    }
                    className={`transition-transform duration-300 ${
                      expandedId === property.id ? "rotate-180" : ""
                    }`}
                  />
                </div>
                <div
                  id={`content-${property.id}`}
                  className="transition-all duration-300 ease-in-out"
                  style={{
                    maxHeight:
                      expandedId === property.id
                        ? `${expandedHeight[property.id]}px`
                        : "0px",
                    opacity: expandedId === property.id ? 1 : 0,
                    overflow: expandedId === property.id ? "visible" : "hidden",
                  }}
                >
                  <div className="p-4">
                    <p className="font-semibold text-xs mb-1">Name</p>
                    {CustomInput(
                      "name",
                      "Name",
                      editedProperties[property.id]?.name || property.name,
                      (e) => handleInputChange(e, property.id)
                    )}
                    <p className="font-semibold text-xs mb-1">Field</p>
                    {CustomInput(
                      "field",
                      "Field",
                      editedProperties[property.id]?.field || property.field,
                      (e) => handleInputChange(e, property.id)
                    )}
                    <p className="font-semibold text-xs mb-1">Description</p>
                    {CustomInput(
                      "description",
                      "Description",
                      editedProperties[property.id]?.description ||
                        property.description,
                      (e) => handleInputChange(e, property.id)
                    )}
                    <div className="mb-2">
                      <p className="font-semibold text-xs mb-1">
                        Assignable to
                      </p>
                      <Dropdown
                        options={assignableToOptions}
                        value={
                          editedProperties[property.id]?.assignable_to || ""
                        }
                        onChange={(value) =>
                          setEditedProperties((prev) => ({
                            ...prev,
                            [property.id]: {
                              ...prev[property.id],
                              assignable_to: value,
                            },
                          }))
                        }
                        label="Assignable to"
                      />
                    </div>
                    <div className="mb-2">
                      <p className="font-semibold text-xs mb-1">Category</p>
                      <Dropdown
                        options={categoryOptions}
                        value={editedProperties[property.id]?.category || ""}
                        onChange={(value) =>
                          setEditedProperties((prev) => ({
                            ...prev,
                            [property.id]: {
                              ...prev[property.id],
                              category: value,
                            },
                          }))
                        }
                        label="Category"
                      />
                    </div>
                    {(editedProperties[property.id]?.category ===
                      "single_select" ||
                      editedProperties[property.id]?.category ===
                        "multi_select") && (
                      <div>
                        <p className="font-semibold mb-1">Options:</p>
                        <CreatableAutocomplete
                          options={editedProperties[property.id]?.options || []}
                          value={editedProperties[property.id]?.options || []}
                          onChange={(newOptions) =>
                            setEditedProperties((prev) => ({
                              ...prev,
                              [property.id]: {
                                ...prev[property.id],
                                options: newOptions,
                              },
                            }))
                          }
                        />
                      </div>
                    )}
                    <button
                      className="mt-4 bg-[#6F23FF] text-white px-4 py-2 rounded-md hover:bg-[#6F23FF] transition-colors"
                      onClick={() => handlePatch(property.id)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
